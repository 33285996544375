import { autoinject } from "aurelia-framework";
import { ApiClient } from "shared/infrastructure";

export interface SigningPageInformation
{
  signingUrl: string,
}

/**
 * Represents a service that manages the company page.
 */
@autoinject
export class SigningService
{
  /**
   * Creates a new instance of the type.
   * @param apiClient The `ApiClient` instance.
   */
  public constructor(apiClient: ApiClient)
  {
    this._apiClient = apiClient;
  }

  private readonly _apiClient: ApiClient;

  /**
   * Get the company information displayed in the .
   * @param signal The abort signal to use, or undefined to use no abort signal.
   * @returns A promise that will be resolved with a list of administrators.
   */
  public async getSigningInformation(signingToken: string, signal?: AbortSignal): Promise<SigningPageInformation>
  {
    const result = await this._apiClient.get(`/document-signing?signingToken=${signingToken}`, { signal });

    return {
      signingUrl: result.data.signingUrl
    };
  }

  public async documentsSigned(signingToken: string, signal?: AbortSignal): Promise<void>
	{
		await this._apiClient.post(
			`/externally-signed`,
			{
        body: {
          signingToken: signingToken
        },
				signal
			}
		);
	}
}
