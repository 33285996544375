import { autoinject } from "aurelia-framework";
import { BaseModule } from "shared/framework/components/base-module/base-module";

@autoinject()
export class SigningCompletedModule extends BaseModule
{
	protected doActivate(): void
	{
		window.parent.postMessage(
			{
				documentsSigned: true
			},
			"*"
		);
	}
}
