import { autoinject, bindable } from "aurelia-framework";
import { SigningService } from "employee-dashboard/model/signing";
import { ToastService } from "shared/framework";
import { BaseModule } from "shared/framework/components/base-module/base-module";
import { Operation } from "shared/utilities";

@autoinject
export class SigningModule extends BaseModule
{
	public constructor(signingService: SigningService, toastService: ToastService)
	{
		super();

		this._signingService = signingService;
		this._toastService = toastService;
		this._contructed = true;
	}

	private readonly _signingService;
	private readonly _toastService;
	private readonly _contructed;

	protected failed: boolean = false;

	@bindable
	protected signingUrl: string;

	protected model: { signingToken: string };

	/**
	 * The most recent update operation
	 */
	protected updateOperation: Operation;

	/**
	 * Called by the framework when the module is activated.
	 * @returns A promise that will be resolved, when the module is activated.
	 */
	public async doActivate(model: { signingToken: string }): Promise<void>
	{
		this.model = model;
		this.update();
	}

	public async attached(): Promise<void>
	{
		window.removeEventListener("message", message => this.documentsSigned(message));
		window.addEventListener("message", message => this.documentsSigned(message));
	}

	private async documentsSigned(message: MessageEvent): Promise<void>
	{
		if (message.data?.documentsSigned)
		{
			await this._signingService.documentsSigned(this.model.signingToken);
		}
	}

	/**
	 * Updates the page by fetching the latest data.
	 */
	 protected update(): void
	 {
		 // Returns if the object is not contructed.
		 // This is needed because the `observable` decorator called the change handler when the
		 // initial property value is set, which happens before the constructor is called
		 if (!this._contructed)
		 {
			 return;
		 }

		 if (this.updateOperation != null)
		 {
			 this.updateOperation.abort();
		 }

		 // Create and execute the operation
		 this.updateOperation = new Operation(async signal =>
		 {
			 this.failed = false;

			 try
			 {
				 // Fetch the data
				 const result = await this._signingService.getSigningInformation(this.model.signingToken, signal);

				 this.signingUrl = result.signingUrl;
			 }
			 catch (error: any)
			 {
				 this.failed = true;
				 this._toastService.open(
					 "error",
					 {
						 "message": error.message
					 }
				 )
			 }
		 })
	 }
}
