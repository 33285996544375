import { autoinject } from "aurelia-framework";
import { PipelineStep, NavigationInstruction, Next } from "aurelia-router";

/**
 * Represents a router pipeline step that determines whether the user is authenticated.
 * If the user is not authenticated this will result in a redirect to the login route.
 */
 @autoinject
export class AuthenticatePipelineStep implements PipelineStep
{
	/**
	* Creates a new instance of the type.
	* @param identityService The `IdentityService` instance.
	*/
	public constructor()
	{
	}
	private timeout: any;

	/**
	* Called by the router when this step should execute.
	* @param navigationInstruction The current navigation instruction.
	* @param next A callback to indicate when pipeline processing should advance to the next step or be aborted.
	* @returns A promise that will be resolved when this step is complete.
	*/
	public async run(navigationInstruction: NavigationInstruction, next: Next): Promise<any>
	{
		clearTimeout(this.timeout);

		return next();
	}
}
